<template>
  <div class="form-group text" :class="{ required: required }">
    <label v-if="label !== ''" :for="input_id">{{ label }}</label>
    <slot name="input" />
    <small :id="'help' + input_id" class="form-text text-muted">
      <slot name="help">
        <div v-html="help"></div>
      </slot>
    </small>
    <div class="invalid-feedback" v-if="error">
      <slot name="invalid-feedback">
        <div>There is an unknown error</div>
      </slot>
    </div>
    <div class="valid-feedback" v-if="isValid">
      <slot name="valid-feedback">
        <div v-html="validMessage"></div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormGroup',
  props: {
    label: {
      type: String,
      required: true
    },
    input_id: {
      type: String
    },
    help: {
      type: String,
      default: ''
    },
    validator: {
      type: Object,
      default: null
    }
  },
  computed: {
    required () {
      if (this.validator == null || this.validator.required == null) {
        return false
      }
      return !this.validator.required
    },
    error () {
      if (this.validator == null) {
        return false
      }
      const v = this.validator
      if (v.$error) {
        return v.$invalid
      } else {
        return false
      }
    },
    isValid () {
      if (
        typeof this.validMessage === 'undefined' ||
        this.validMessage === ''
      ) {
        return false
      } else {
        return true
      }
    }
  }
}
</script>

<style scoped></style>
